<mat-progress-bar
  mode="query"
  color="warn"
  *ngIf="isShowingRouteLoadIndicator"
  style="z-index: 1"
>
</mat-progress-bar>
<ngx-spinner
  bdColor="rgba(33,83,63,0.79)"
  size="medium"
  color="#fff"
  type="square-loader"
  [fullScreen]="true"
  ><p style="color: white; margin-top: 1rem; text-align: center">
    Processing request
  </p></ngx-spinner
>
<router-outlet></router-outlet>
<div class="noty" id="panel">
  <div class="content">
    <div class="text-right mb-2">
      <i class="fas fa-times text-danger cursor" (click)="menu()"></i>
    </div>
    <h6 class="text-center head f-w6 f-16 mb-4">
      V {{ version?.number }} : Version Release Note
    </h6>
    <div>
      <h6 class="text-app-primary f-14 f-w6">PIN Generation</h6>
      <ul class="list-unstyled">
        <li>
          In the wake of present insecurity within the state, All PINs are now
          purchased online wihtout needing to visit any physical office. This
          allows schools/users not to travel a long distance to purchase PIN
        </li>
        <li>
          Each PIN has a specific type of action it can be used for. These
          include : <br />
          a. These are Registration - For new schools <br />
          b. Transfer - Students who are moving from one school to another
          <br />
          c. New admission - For students who have never existed in the portal
          <br />
        </li>
        <li>
          Registration PINs are sent via sms after purchase for security
          purposes.
        </li>
        <li>
          Each PIN has a quatity which defines the maximum number of students it
          can be used for. With this, you wont have to buy multiple PINs to
          allow you admit 10 students. A PIN is a token that allows multiple use
          based on the quatity you chose when purchasing it
        </li>
        <li>
          Each PIN cannot be used by a different school. Only the school that
          purchased it from their dashboard can use it. This applies to Transfer
          and New Admission PINs
        </li>
        <li>
          After purchase, the PINs are available in the admission center page
          from where you can copy them for use
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="noty_btn bg-app-primary" id="slider-button" (click)="menu()">
  <i class="fas fa-cog fa-spin"></i>
</div>

<!-- <div class="construction">
  <div class="image">
    <img src="../assets/img/logo.png" alt="" />
  </div>
  <div>
    <h6>Plsease bear with us, portal is currently offline</h6>
    <p>We are upgrading it to serve you better.. we bill be back shortly</p>
  </div>
</div> -->
