import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import { CheckResultComponent } from './Core/check-result/check-result.component';
import { ForgotPasswordComponent } from './Core/forgot-password/forgot-password.component';
import { LoginComponent } from './Core/login/login.component';
import { RegisterComponent } from './Core/register/register.component';
import { ResetPasswordComponent } from './Core/reset-password/reset-password.component';
import { AuthGuard } from './Guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data: { title: 'Login | Examportal Nigeria' },
  },

  {
    path: 'register',
    component: RegisterComponent,
    data: { title: 'Register | Examportal Nigeria' },
  },
  {
    path: 'check-result',
    component: CheckResultComponent,
    data: { title: 'Check Result | Examportal Nigeria' },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: 'Forgot Password | Examportal Nigeria' },
  },

  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { title: 'Reset Password | Examportal Nigeria' },
  },
  {
    path: 'payment/status',
    component: ConfirmPaymentComponent,
    data: { title: 'Payment Status | Examportal Nigeria' },
  },

  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: {
      title: 'School Dashboard | Examportal Nigeria',
    },
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
