export const environment = {
  production: false,
  // url: `http://localhost:50000/api/schools/`,
  url: `https://staging.api.examportal.ng/api/schools/`,
  // url: `https://api.examportal.ng/api/schools/`,
  // base: `http://localhost:50000/api/`,
  base: `https://staging.api.examportal.ng/api/`,
  //base: `https://api.examportal.ng/api/`,
  idlUrl: `https://safe-payy.herokuapp.com/`,
  adminUrl: 'https://staging.api.examportal.ng/api/admins/',
  checkStudentsProfile: false,
  unifiedUrl: 'https://test.payarena.com/',
  monefyUrl: 'https://sdk.monnify.com/checkout/',
};
