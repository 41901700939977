<div class="wrapper">
  <img src="../../../assets/img/top_Vector.svg" alt="" class="top_vector" />
  <img
    src="../../../assets/img/bottom_vector.svg"
    alt=""
    class="bottom_vector"
  />
  <div class="side_bg">
    <div class="text">
      <h6 class="text-white fw-800 f-40">Examportal</h6>
      <p class="text-white">School management made easy</p>
    </div>
  </div>
  <div class="content">
    <div class="form_area">
      <div class="top text-center">
        <img src="../../../assets/img/logo.png" alt="" /> <br />
        <span class="font-weight-bold f-16 text-app-secondary"
          >Examportal Nigeria</span
        >
        <h6 class="f-16 mt-3">
          Check Student Result
          <span class="bg-warning ml-3 soon f-12 text-white">coming Soon</span>
        </h6>
      </div>

      <form [formGroup]="resultForm">
        <div class="row">
          <div class="group col-12">
            <input
              type="text"
              id="text"
              formControlName="pin"
              class=""
              placeholder="Access PIN"
            />
          </div>
          <div class="group col-12">
            <input
              type="text"
              class=""
              formControlName="regno"
              id="pwd"
              placeholder="Student Reg Number"
            />
          </div>
          <div class="group select col-12">
            <select
              name="exam"
              id=""
              class="custom-select"
              formControlName="exam"
            >
              <option value="" disabled selected>Select Examination</option>
              <option *ngFor="let item of exams" [value]="item?.title">
                {{ item?.title }}
              </option>
            </select>
            <img src="../../../assets/img/select arrow.svg" alt="" />
          </div>
          <div class="group select col-12">
            <select
              name="lga"
              id=""
              class="custom-select"
              formControlName="session"
            >
              <option value="" disabled selected>Select Session</option>
              <option *ngFor="let item of sessions" [value]="item">
                {{ item }}
              </option>
            </select>
            <img src="../../../assets/img/select arrow.svg" alt="" />
          </div>
          <div class="group select col-12">
            <select
              name="term"
              id=""
              class="custom-select"
              formControlName="term"
              disabled
            >
              <option value="3">Third term</option>
            </select>
            <img src="../../../assets/img/select arrow.svg" alt="" />
          </div>
        </div>
        <button
          class="bg-app-secondary text-white btn w-100"
          (click)="login()"
          [disabled]="!resultForm.valid || loader"
        >
          Continue
          <i class="fas fa-circle-notch fa-spin ml-4" *ngIf="loader"></i>
        </button>
      </form>
      <footer class="text-center f-14">
        <span class="text-app-secondary">Already have a portal Account?</span>
        <span class="cursor ml-1 text-app-primary" [routerLink]="['/']"
          >Login</span
        >
      </footer>
    </div>
  </div>
</div>
