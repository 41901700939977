<div class="wrapper">
  <img src="../../../assets/img/top_Vector.svg" alt="" class="top_vector" />
  <img
    src="../../../assets/img/bottom_vector.svg"
    alt=""
    class="bottom_vector"
  />
  <div class="side_bg">
    <div class="text">
      <h6 class="text-white fw-800 f-40">Examportal</h6>
      <p class="text-white">School management made easy</p>
      <p class="text-white">In Partnership with Exam Development Center</p>
    </div>
  </div>
  <div class="content">
    <div class="form_area">
      <div class="top text-center">
        <img src="../../../assets/img/logo.png" alt="" /> <br />
        <span class="font-weight-bold f-16 text-app-secondary"
          >Examportal Nigeria</span
        >
        <h6 class="f-16 mt-3">Sign in to Your Account</h6>
      </div>

      <form [formGroup]="loginForm">
        <div class="row">
          <div class="group col-12">
            <input
              type="text"
              id="email"
              formControlName="email"
              class=""
              placeholder="Email Address"
            />
          </div>
          <div class="group col-12">
            <input
              type="password"
              class=""
              formControlName="password"
              id="pwd"
              placeholder="Password"
            />
          </div>
        </div>
        <p class="f-14 text-right">
          <span
            class="cursor text-app-primary"
            [routerLink]="['/forgot-password']"
            >Forgot password?</span
          >
        </p>
        <button
          class="bg-app-secondary text-white btn w-100"
          (click)="login()"
          [disabled]="!loginForm.valid || loader"
        >
          Sign In
          <i class="fas fa-circle-notch fa-spin ml-4" *ngIf="loader"></i>
        </button>
      </form>
      <footer class="text-center f-14">
        <div class="mb-3">
          <span class="text-app-secondary"
            >Not yet registered on Examportal?</span
          >
          <span
            class="cursor ml-2 text-app-primary"
            [routerLink]="['/register']"
            >Register</span
          >
        </div>

        <span>
          Support:
          <a href="tel:+" class="text-app-primary ml-1">07033902120</a> or
          <a href="tel:+ 07066507116" class="ml-1 text-app-primary"
            >07066507116</a
          >
        </span>
        <div class="text-center my-4">
          <span
            class="btn bg-app-primary text-white text-decoration-none"
            [routerLink]="['/check-result']"
          >
            Check Result
          </span>
        </div>
      </footer>
    </div>
  </div>
</div>
