import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { terms } from '../dashboard/data';
import {
  adult,
  adult_array,
  primary,
  primary_array,
  secondary,
  secondary_array,
} from '../dashboard/school_classes';
import { DefaultSubject, subjects } from '../dashboard/subjects';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  encryptSecretKey = 'diego';
  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private jwtHelper: JwtHelperService,
    private snackBar: MatSnackBar,
    private cookieService: CookieService
  ) {}

  private studentsSubject = new BehaviorSubject<any>('');
  studentData = this.studentsSubject.asObservable();

  private minSubjectSubject = new BehaviorSubject<any>('');
  minSubjectData = this.minSubjectSubject.asObservable();

  private schoolSubjectSubject = new BehaviorSubject<any>('');
  schoolSubjectData = this.schoolSubjectSubject.asObservable();

  private invoiceSubject = new BehaviorSubject<any>('');
  invoiceData = this.invoiceSubject.asObservable();

  private profileSubject = new BehaviorSubject<any>('');
  profileChange = this.profileSubject.asObservable();

  private schoolSubject = new BehaviorSubject<any>('');
  profle = this.schoolSubject.asObservable();

  storeStudents(data): void {
    this.studentsSubject.next(data);
  }

  storeProfile(data): void {
    this.schoolSubject.next(data);
  }

  storeInvoice(data): void {
    this.invoiceSubject.next(data);
  }

  storeMinistrySubjects(data): void {
    this.minSubjectSubject.next(data);
  }

  storeSchoolSubjects(data): void {
    this.schoolSubjectSubject.next(data);
  }

  setProfileChange(data): void {
    this.profileSubject.next(data);
  }

  removeAllData() {
    this.setProfileChange(null);
    this.storeSchoolSubjects(null);
    this.storeStudents(null);
    this.storeMinistrySubjects(null);
    this.storeInvoice(null);
    this.storeProfile(null);
  }
  setUserObject(userObject): void {
    localStorage.setItem('/youza', JSON.stringify(userObject));
  }

  getUserObject(): any {
    const user = localStorage.getItem('/youza');
    if (user) {
      return JSON.parse(user);
    }
  }

  // Temporary offline data saving
  saveOffline(data): void {
    localStorage.setItem('/offline', JSON.stringify(data));
  }
  removeOffline(): void {
    localStorage.removeItem('/offline');
  }
  getOffline(): any {
    const data = localStorage.getItem('/offline');
    if (data) {
      return JSON.parse(data);
    }
  }

  refresh(): void {
    window.location.reload();
  }

  // AUTH TOKEN
  setToken(token): void {
    localStorage.setItem('/key', token);
  }
  getToken(): any {
    const token = localStorage.getItem('/key');
    if (token) {
      return token;
    }
  }

  // Invoice
  setInvoice(data): void {
    localStorage.setItem('/inv', JSON.stringify(data));
  }
  getInvoice(): any {
    const invoice = localStorage.getItem('/inv');
    if (invoice) {
      return JSON.parse(invoice);
    }
  }

  isAthourized(allowedUsertypes: string[]): any {
    // check if the list of allowedusertpes for aroute is empty,
    // if empty, authorize the user to access the page
    if (allowedUsertypes == null || allowedUsertypes.length === 0) {
      return true;
    }
    const user = this.getUserObject();
    if (user) {
      const authUsertype = user.role;
      return allowedUsertypes.includes(authUsertype);
    }
  }

  deleteAllCookie(): void {
    this.cookieService.deleteAll();
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  }

  getUserType(): any {
    const token = localStorage.getItem('/key');
    return this.jwtHelper.decodeToken(token).scopes;
  }
  isLoggedIn(): boolean {
    const token = localStorage.getItem('/key');
    if (!token || this.jwtHelper.isTokenExpired(token)) {
      return false;
    }
    return true;
  }

  logout(): void {
    localStorage.removeItem('/key');
    localStorage.removeItem('/youza');
    this.router.navigateByUrl('/');
    // this.refresh();
  }

  // NAVIGATION TO SEARCH PAGE
  searchRoute(path, query): void {
    this.router.navigate([path], {
      queryParams: {
        tag: query,
      },
      queryParamsHandling: 'merge',
    });
  }

  // SNACKBAR METHODS
  succesSnackbar(msg): void {
    this.snackbarConfig('Success', msg, 'success-snackbar');
  }

  errorSnackbar(msg): void {
    this.snackbarConfig('Error', msg, 'error-snackbar');
  }

  warningSnackbar(msg): void {
    this.snackbarConfig('Warning', msg, 'warning-snackbar');
  }

  snackbarConfig(title, msg, theme): void {
    this.snackBar.open(title, msg, {
      duration: 7000,
      verticalPosition: 'top',
      // horizontalPosition: 'right',
      panelClass: [theme],
    });
  }

  // MATERIAL DIALOG HANDLES
  dialogConfig(): any {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.closeOnNavigation = true;
    return dialogConfig;
  }
  // open(item: Object): void {
  //   const dialogConfig = this.dialogConfig();
  //   dialogConfig.data = item;
  //   dialogConfig.width = '100%';
  //   this.matDialog.open(ImagePreviewDioalgComponent, dialogConfig);
  // }

  // to replace multiple white space with one space
  public trimWhiteSpace(text: string): string {
    const formattedText = text.trim().replace(/\s+/g, ' ');
    return formattedText;
  }

  // Format Class Name
  parseClass(val): string {
    const type = this.getUserObject().type;
    if (type === '50') {
      return `Primary ${Number(val)}`;
    }
    if (type === '50' && val < 10) {
      return `JS ${Number(val) - 6}`;
    }
    if (type === '50' && val > 9) {
      return `SS ${Number(val) - 9}`;
    }
  }

  parseSubjectClass(klas): string {
    const classes = primary.concat(secondary).concat(adult);
    let str = '';
    const classItem = classes.find((el) => {
      return el.code === klas;
    });
    if (classItem) {
      str = classItem.title;
    }
    return str;
  }

  checkFullName(studentList) {
    studentList.map((student) => {
      student.fullname = this.trimWhiteSpace(student.fullname);
    });
    const errorList = studentList.filter((element) => {
      return (
        element.fullname.split(' ').length < 3 ||
        element.fullname.split(' ').length > 3
      );
    });
    const filePassed = errorList.length === 0;
    if (!filePassed) {
      this.warningSnackbar(
        'Student name must containe three names seperated with white spaces'
      );
    }
    return { studentList, filePassed };
  }

  validateUpload(data): boolean {
    const filter = data.filter((item) => {
      return (
        item.class.toLowerCase() !== 'daycare' &&
        item.class.toLowerCase() !== 'creche' &&
        item.class.toLowerCase() !== 'prenursery' &&
        item.class.toLowerCase() !== 'nursery1' &&
        item.class.toLowerCase() !== 'nursery2' &&
        item.class.toLowerCase() !== 'nursery3' &&
        item.class.toLowerCase() !== 'primary1' &&
        item.class.toLowerCase() !== 'primary2' &&
        item.class.toLowerCase() !== 'primary3' &&
        item.class.toLowerCase() !== 'primary4'
      );
      // item.class.toLowerCase() !== 'primary5' &&
      // item.class.toLowerCase() !== 'primary6' &&
      // item.class.toLowerCase() !== 'js1' &&
      // item.class.toLowerCase() !== 'js2' &&
      // item.class.toLowerCase() !== 'js3' &&
      // item.class.toLowerCase() !== 'jss1' &&
      // item.class.toLowerCase() !== 'jss2' &&
      // item.class.toLowerCase() !== 'jss3' &&
      // item.class.toLowerCase() !== 'ss1' &&
      // item.class.toLowerCase() !== 'ss2' &&
      // item.class.toLowerCase() !== 'ss3';
    });
    if (filter.length > 0) {
      this.warningSnackbar(
        'Please check the supplied classes, some or all may be incorrect'
      );
      return false;
    }
    const incomplete = data.filter((item) => {
      return (
        item.class === '' ||
        item.fullname === '' ||
        item['DOB(month/day/year)'] === ''
      );
    });

    if (incomplete.length > 0) {
      this.warningSnackbar(
        'Please enter the required fields: names, DOB, class & gender'
      );
      return false;
    }
    return true;
  }

  parseFile(data): any {
    console.log(data);

    data.forEach((file) => {
      file.dob = file['DOB(month/day/year)'];
      delete file['DOB(month/day/year)'];
      file.class = file.class.toString().toLowerCase();
      if (file.class.includes('day') || file.class.includes('care')) {
        file.class = 100;
      }
      if (file.class.includes('creche')) {
        file.class = 200;
      }
      if (file.class === 'prenursery') {
        file.class = 300;
      }
      if (file.class === 'nursery1' || file.class === 'nursery 1') {
        file.class = 400;
      }
      if (file.class === 'nursery2' || file.class === 'nursery 2') {
        file.class = 500;
      }
      if (file.class === 'nursery3' || file.class === 'nursery 3') {
        file.class = 600;
      }
      if (file.class === 'primary1' || file.class === 'primary 1') {
        file.class = 1;
      }
      if (file.class === 'primary2' || file.class === 'primary 2') {
        file.class = 2;
      }
      if (file.class === 'primary3' || file.class === 'primary 3') {
        file.class = 3;
      }
      if (file.class === 'primary4' || file.class === 'primary 4') {
        file.class = 4;
      }
      if (file.class === 'primary5' || file.class === 'primary 5') {
        file.class = 5;
      }
      if (file.class === 'primary6' || file.class === 'primary 6 ') {
        file.class = 6;
      }
      if (file.class === 'adult') {
        file.class = 1000;
      }
      if (
        file.class === 'js1' ||
        file.class === 'jss1' ||
        file.class === 'jss 1'
      ) {
        file.class = 7;
      }
      if (
        file.class === 'js2' ||
        file.class === 'jss2' ||
        file.class === 'jss 2'
      ) {
        file.class = 8;
      }
      if (
        file.class === 'js3' ||
        file.class === 'jss3' ||
        file.class === 'jss 3'
      ) {
        file.class = 9;
      }
      if (file.class === 'ss1' || file.class === 'ss 1') {
        file.class = 10;
      }
      if (file.class === 'ss2' || file.class === 'ss 2') {
        file.class = 11;
      }
      if (file.class === 'ss3' || file.class === 'ss 3') {
        file.class = 12;
      }
    });
    return data;
  }

  validateClass(data): boolean {
    const classes = this.schoolClasses();
    const filter = data.filter((item) => {
      return !classes.includes(item.class);
    });
    const odd = data.filter((item) => {
      return item.class >= 5 && item.class <= 12;
    });
    if (filter.length > 0 || odd.length > 0) {
      this.warningSnackbar(
        'You cannot add students in some or all of the provided classes'
      );
      return false;
    }
    return true;
  }

  // process error message
  parseError(errObj, error): string {
    let msg = '';
    if (error.message) {
      msg = error.message;
    }
    if (error.error) {
      msg = error.error;
    }
    if (error.error && error.text) {
      msg = error.text;
    }
    if (typeof error === 'string') {
      msg = error;
    }
    if (errObj.status === 404) {
      msg = errObj.statusText;
    }
    if (typeof error !== 'string' && error.error && error.message) {
      msg = 'Operation failed, contract admin if eror persists';
    }
    return msg;
  }

  // GEt school classes
  schoolClasses(): Array<any> {
    if (this.getUserObject().type === '50') {
      return primary_array;
    } else if (this.getUserObject().type === '51') {
      return secondary_array;
    } else {
      return adult_array;
    }
  }

  // Method to return list of sessions according to calendar year
  sessionDropdown(): Array<any> {
    const options = [];
    for (let index = 0; index < 2; index++) {
      const current = new Date().getFullYear() + 1 - index;
      const prev = current - 1;
      options.push(`${prev}/${current}`);
    }
    return options;
  }

  // Format invoice reference to contain dash after 3 digits
  formatRef(ref): string {
    return ref.match(/\d{3}(?=\d{2,3})|\d+/g).join('-');
  }

  // Revert invoice reference formating
  revertRef(ref): string {
    return ref.match(/\d{3}(?=\d{2,3})|\d+/g).join('');
  }

  // Method to sort students by ascending order of class
  sortData(data: Array<any>, klass: string): Array<any> {
    const sorted = data.sort((a, b) => {
      return Number(a[klass]) - Number(b[klass]);
    });
    return sorted;
  }

  // Transofrm Result to contain subject name and grade
  getSubjectsList(results: Array<any>): Array<any> {
    const subArray = [];
    results.forEach((item) => {
      item.subjects.forEach((element) => {
        if (!subArray.includes(element.subject_code)) {
          subArray.push(element.subject_code);
        }
      });
    });
    return subArray;
  }

  transformResult(results: Array<any>): any {
    // Remove duplicates
    results.map((item) => {
      item.subjects = _.uniqBy(item.subjects, (obj: any) => obj.subject_code);
    });
    const subjectList = this.getSubjectsList(results);
    results.forEach((item) => {
      item.marks_scored = item.total_scored;
      const newsubjects = [];
      for (let index = 0; index < subjectList.length; index++) {
        DefaultSubject.sr = index;
        newsubjects.push(DefaultSubject);
      }
      item.subjects.map((element) => {
        const exam = element.score ? element.score : 0;
        const ca1 = element.first_ca ? element.first_ca : 0;
        const ca2 = element.second_ca ? element.second_ca : 0;
        const score = exam + ca1 + ca2;
        element.score = score;
        element.grade = this.determineGrade(score).grade;
        element.remark = this.determineGrade(score).remark;
        // rearranging the results in the subject list order
        const newIndx = subjectList.indexOf(element.subject_code);
        newsubjects[newIndx] = element;
      });
      item.term_title = this.determineTerm(item.term);
      item.new_class = this.parseSubjectClass(item.class + 1);
      item.prev_class = this.parseSubjectClass(item.class);
      item.subjects = newsubjects;
    });

    return {
      subject_list: subjectList,
      result_list: results,
    };
  }
  // To get term string instead of number
  determineTerm(term: number): string {
    const termStr = terms.filter((el) => {
      return el.term === term;
    });
    return termStr[0].title;
  }

  // To process grades for different scores
  determineGrade(scoreStr): any {
    const grading = {
      grade: '',
      remark: '',
    };
    const score = Number(scoreStr);
    if (score < 40) {
      grading.grade = 'F';
      grading.remark = 'Fail';
    }
    if (score >= 40 && score < 50) {
      grading.grade = 'P';
      grading.remark = 'Pass';
    }
    if (score >= 50 && score < 60) {
      grading.grade = 'C';
      grading.remark = 'Credit';
    }
    if (score >= 60 && score < 70) {
      grading.grade = 'B';
      grading.remark = 'Good';
    }
    if (score >= 70) {
      grading.grade = 'A';
      grading.remark = 'Excellent';
    }
    return grading;
  }

  schoolClass(): Array<any> {
    if (this.getUserObject().type === '50') {
      return primary;
    }
    if (this.getUserObject().type === '51') {
      return secondary;
    }
    if (this.getUserObject().type === '52') {
      return adult;
    }
  }
}

// if(file.class.toLowerCase() == 'primary3'){
//   file.class = 3
// }
// if(file.class.toLowerCase() == 'primary2'){
//   file.class = 2
// }
// if(file.class.toLowerCase() == 'primary1'){
//   file.class = 1
// }
