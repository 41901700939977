<mat-dialog-content class="dialog-sm" class="dialog-sm">
  <div class="dialog-container">
    <div class="bg-white radius-md py-4 px-4">
      <div class="mb-4">
        <div class="flex_x_between">
          <h6 class="text-app-primary font-weight-bold f-24">
            Generate Registration PIN
          </h6>
          <i
            class="fas fa-times cursor text-danger f-24"
            (click)="decline()"
            *ngIf="!loader"
          ></i>
        </div>
        <small id="emailHelp" class="form-text text-muted"
          >This PIN can only be used once for registering a new school</small
        >
      </div>

      <form [formGroup]="pinForm" class="admission">
        <div class="row">
          <div class="group col-12">
            <label for="phone" Name>Name</label>
            <input
              type="text"
              name="name"
              id="name"
              formControlName="name"
              placeholder="eg. Okereke Chinedu"
            />
          </div>
          <div class="group col-12">
            <label for="phone" Name>Phone Number</label>
            <input
              type="text"
              name="phone"
              id="phone"
              formControlName="phone"
              placeholder="eg. 0706000000"
            />
          </div>
          <div class="group col-12">
            <label for="phone" Name>Email Address</label>
            <input
              type="email"
              name="email"
              id="email"
              formControlName="email"
              placeholder="eg. customer@gmail.com"
            />
          </div>
          <div class="col-12 mt-3">
            <div class="info-box" *ngIf="paymentData">
              <span class="title">Amount to Pay:</span> ₦{{
                paymentData.amount
              }}
            </div>
          </div>
          <div class="col-12 mt-3">
            <button
              class="bg-app-secondary text-white btn w-100"
              (click)="generate()"
              [disabled]="loader"
              *ngIf="!paymentData"
            >
              Buy
              <i class="fas fa-circle-notch fa-spin ml-4" *ngIf="loader"></i>
            </button>
            <button
              class="bg-app-secondary text-white btn w-100"
              (click)="pay()"
              *ngIf="paymentData"
            >
              Proceed to pay
              <i class="fas fa-circle-notch fa-spin ml-4" *ngIf="loader"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
