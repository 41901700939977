<div class="wrapper">
    <img src="../../../assets/img/top_Vector.svg" alt="" class="top_vector">
    <img src="../../../assets/img/bottom_vector.svg" alt="" class="bottom_vector">
    <div class="side_bg">
      
        <div class="text">
            <h6 class="text-white fw-800 f-40">
                Examportal
            </h6>
            <p class="text-white">
                School management made easy
            </p>
        </div>
    </div>
    <div class="content">

        <div class="form_area">
            <div class="top text-center">
                <img src="../../../assets/img/logo.png" alt=""> <br>
                <span class="font-weight-bold f-16 text-app-secondary">Examportal Nigeria</span>
                <h6 class="f-16 mt-3">
                    Recover Password
                </h6>
            </div>
            
            <form [formGroup]="resetForm">
                <div class="row">
                   <div class="group col-12">
                        <input type="text" id="email" formControlName="email" class="" placeholder="Email Address">
                    </div>
                </div>
                <button class="bg-app-secondary text-white btn w-100"
                (click)="sendLink()" [disabled]="!resetForm.valid || loader">Send Token
                <i class="fas fa-circle-notch fa-spin ml-4" *ngIf="loader"></i>
                </button>
            </form>
            <footer class="text-center f-14">
              <span class="text-app-secondary">Already have a portal account?</span>
              <span class="cursor ml-1 text-app-primary" [routerLink]="['/']">Login</span>
          </footer>
        </div>
    </div>
  </div>
  