export const terms = [
    {
        term: 1,
        title: "First Term"
    },
    {
        term: 2,
        title: "Second Term"
    },
    {
        term: 3,
        title: "Third Term"
    }
]