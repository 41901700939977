import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { UtilService } from 'src/app/Services/util.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  resetForm: FormGroup;
  loader = false;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private util: UtilService
  ) { 
    this.resetForm = this.formbuilder.group({
      email : ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }
  async sendLink(){
    this.loader = true;
    this.api.recoverPassword(this.resetForm.value.email).subscribe((res:any)=>{
      this.util.succesSnackbar(res.msg);
      this.router.navigate(['/reset-password'])
    },err =>(
      this.util.warningSnackbar(err.error || 'Network failed, try again'),
      this.loader = false
    ))
  }

}

