import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GeneratePinResponse } from '../models';
import { ApiService } from '../../Services/api.service';
import { UtilService } from '../../Services/util.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-generateregpin',
  templateUrl: './generateregpin.component.html',
  styleUrls: ['./generateregpin.component.scss'],
})
export class GenerateregpinComponent implements OnInit {
  pinForm: FormGroup = this.formbuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: [
      '',
      [Validators.required, Validators.maxLength(11), Validators.minLength(11)],
    ],
  });
  loader = false;
  profileSubscription: Subscription;
  paymentData: GeneratePinResponse;
  constructor(
    private dialogRef: MatDialogRef<GenerateregpinComponent>,
    private api: ApiService,
    private util: UtilService,
    private formbuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  generate() {
    if (this.pinForm.invalid) {
      this.pinForm.markAllAsTouched();
      this.util.warningSnackbar(
        'Please check the fields. Phone number must be 11 digits'
      );
    } else {
      this.loader = true;
      this.api.generateRegPinMonefy(this.pinForm.value).subscribe({
        next: (res) => {
          this.loader = false;
          this.paymentData = res.data;
        },
        error: () => {
          this.loader = false;
        },
      });
    }
  }

  pay(): void {
    if (this.paymentData.transaction.payment_gateway === 'monnify') {
      window.open(
        this.paymentData.transaction.other_details[0].checkoutUrl,
        '_blank'
      );
    } else {
      window.open(
        environment.unifiedUrl + this.paymentData.transactionID,
        '_blank'
      );
    }
    this.dialogRef.close();
  }

  decline(): void {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    this.profileSubscription?.unsubscribe();
  }
}
