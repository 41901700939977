import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Session } from 'protractor';
import { exams } from 'src/app/dashboard/exams';
import { ApiService } from 'src/app/Services/api.service';
import { UtilService } from 'src/app/Services/util.service';

@Component({
  selector: 'app-check-result',
  templateUrl: './check-result.component.html',
  styleUrls: ['./check-result.component.scss']
})
export class CheckResultComponent implements OnInit {
  resultForm: FormGroup;
  loader = false;
  sessions = [];
  exams = exams;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private util: UtilService
  ) {
    this.resultForm = this.formbuilder.group({
      pin: ['', Validators.required],
      regno: ['', Validators.required],
      session: ['', Validators.required],
      exam: ['', Validators.required],
      term: ['3', Validators.required],
    });
  }

  ngOnInit(): void {
    this.sessions = this.util.sessionDropdown();
    // if(this.util.isLoggedIn()){
    //   this.util.succesSnackbar('You are already Signed In')
    //   this.router.navigate(['/dashboard'])
    // }
  }
  async login(): Promise<void> {
    this.util.warningSnackbar('Feature availabale soon');
    // this.loader = true;
    // this.api.login(this.loginForm.value).subscribe((res: any) => {
    //   this.util.succesSnackbar('Login successful');

    // }, err => (
    //   this.loader = false
    // ));
  }


}
