import { catchError, retry, tap } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { FeesQuery } from '../Core/models';
import { RequestService } from './request/request.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl = environment.url;
  baseUrlBase = environment.base;
  idlUrl = environment.idlUrl;
  adminUrl = environment.adminUrl;
  errorHandler(error: HttpErrorResponse): any {
    return throwError(error.message || 'server error.');
  }
  constructor(private http: HttpClient, private reqS: RequestService) {}

  profile(): Observable<any> {
    const getUrl = this.baseUrl + 'profile';
    return this.http.get(getUrl).pipe(retry(1), tap());
  }
  editStudent(data: FormData): Observable<any> {
    const getUrl = this.baseUrl + 'save-profile-image';
    return this.http.post(getUrl, data).pipe(tap());
  }
  updateProfile(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'profile/edit';
    return this.http.patch(getUrl, data).pipe(tap());
  }
  updatePassword(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'update_password';
    return this.http.patch(getUrl, data).pipe(tap());
  }
  signup(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'registration';
    return this.http.post(getUrl, data).pipe(tap());
  }

  login(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'login';
    return this.http.post(getUrl, data).pipe(tap());
  }

  logoutDevice(): Observable<any> {
    const getUrl = this.baseUrl + 'logout';
    return this.http.get(getUrl).pipe(tap());
  }

  logoutAll(): Observable<any> {
    const getUrl = this.baseUrl + 'logout/all';
    return this.http.get(getUrl).pipe(tap());
  }

  getCategroy(): Observable<any> {
    const getUrl = this.adminUrl + 'categories?skip=0';
    return this.http.get(getUrl).pipe(tap());
  }

  getTypes(): Observable<any> {
    const getUrl = this.adminUrl + 'all-types?skip=0';
    return this.http.get(getUrl).pipe(tap());
  }

  recoverPassword(email: object): Observable<any> {
    const getUrl = this.baseUrl + `request_password_reset/${email}`;
    return this.http.get(getUrl).pipe(tap());
  }

  resetPassword(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'change_password_with_token';
    return this.http.post(getUrl, data).pipe(tap());
  }

  uploadStudent(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'register/bulk-student';
    return this.http.post(getUrl, data).pipe(tap());
  }

  updateStudent(id: string, data: object): Observable<any> {
    const getUrl = this.baseUrl + `student/profile/edit/${id}`;
    return this.http.patch(getUrl, data).pipe(tap());
  }

  updatePassport(reg: string, data: FormData): Observable<any> {
    const getUrl = this.baseUrl + `student-passport/${reg}`;
    return this.http.patch(getUrl, data).pipe(tap());
  }

  allStudents(data: any): Observable<any> {
    const getUrl =
      this.baseUrl +
      `students?skip=0&limit=&gradstatus=${data.gradstatus}&class=${
        data.class ? data.class : ''
      }`;
    return this.http.get(getUrl, data).pipe(retry(1), tap());
  }

  deleteStudents(data: object): Promise<any> {
    const getUrl = this.baseUrl + 'delete_students';
    return this.http.post(getUrl, data).toPromise();
  }

  getConfig(): Observable<any> {
    const getUrl = this.baseUrlBase + 'configs/get-config';
    return this.http.get(getUrl);
  }

  transfer(data: object): Observable<any> {
    const getUrl = this.baseUrlBase + 'pins/transfer_students';
    return this.http.post(getUrl, data).pipe(tap());
  }

  admitJs1(data: object): Observable<any> {
    const getUrl = this.baseUrlBase + 'pins/intra_school_admission';
    return this.http.post(getUrl, data).pipe(tap());
  }

  admitSs1(data: object): Observable<any> {
    const getUrl = this.baseUrlBase + 'pins/inter_school_admission';
    return this.http.post(getUrl, data).pipe(tap());
  }

  updateFee(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'update_classes_fee';
    return this.http.patch(getUrl, data).pipe(tap());
  }

  getClassFees(): Observable<any> {
    const getUrl = this.baseUrl + 'classes_fee';
    return this.http.get(getUrl).pipe(retry(1), tap());
  }

  getAllTransactions(query: any): Observable<any> {
    const getUrl =
      this.baseUrl +
      `get-transactions?session=${query.session}&term=${query.session}&type=${query.type}&status=true&skip=${query.skip}&limit=${query.limit}`;
    return this.http.get(getUrl).pipe(retry(1), tap());
  }

  getInvoiceTransactions(query: any): Observable<any> {
    const getUrl =
      this.baseUrl +
      `get-transactions?session=${query.session}&term=${query.term}&type=invoice&status=true&skip=${query.skip}&limit=${query.limit}`;
    return this.http.get(getUrl).pipe(retry(1), tap());
  }

  getSchoolFeesTransactions(query: any): Observable<any> {
    const getUrl =
      this.baseUrl +
      `get-transactions?session=${query.session}&term=${query.term}&type=school_fee&status=true&skip=${query.skip}&limit=${query.limit}`;
    return this.http.get(getUrl).pipe(retry(1), tap());
  }

  searchStudentByreg(reg: string): Observable<any> {
    const getUrl = this.baseUrl + `student_regno/${reg}`;
    return this.http.get(getUrl).pipe(retry(2), tap());
  }

  verifyStudent(data): Observable<any> {
    const getUrl = this.baseUrlBase + `students/verify_student_exists`;
    return this.http.post(getUrl, data).pipe(tap());
  }

  admitWithPIN(data): Observable<any> {
    const getUrl = this.baseUrlBase + `students/register/new_student`;
    return this.http.post(getUrl, data).pipe(tap());
  }
  generatePIN(data): Observable<any> {
    const getUrl = this.baseUrlBase + `payments/init-pin-payment`;
    return this.http.post(getUrl, data);
  }
  generatePinMonefy(data: {
    type: string;
    phone: string;
    quantity: number;
  }): Observable<any> {
    const getUrl = this.baseUrlBase + `payments/init-pin-payment-monnify`;
    return this.http.post(getUrl, data);
  }
  generateRegPIN(data: {
    name: string;
    phone: string;
    email: string;
  }): Observable<any> {
    const getUrl =
      this.baseUrlBase +
      `payments/init-regpin-payment
`;
    return this.http.post(getUrl, data);
  }
  generateRegPinMonefy(data: {
    name: string;
    phone: string;
    email: string;
  }): Observable<any> {
    const getUrl =
      this.baseUrlBase +
      `payments/init-regpin-payment-monnify
`;
    return this.http.post(getUrl, data);
  }
  myPins(): Observable<any> {
    const getUrl = this.baseUrlBase + `pins/all-pins`;
    return this.http.get(getUrl);
  }

  pinDetails(pin: string): Observable<any> {
    const getUrl = this.baseUrlBase + `pins/get-pin`;
    return this.http.get(getUrl);
  }

  transactionByRef(ref: string): Observable<any> {
    const getUrl = this.baseUrlBase + `payments/get-transaction/${ref}`;
    return this.http.get(getUrl);
  }

  pinTransactions(): Observable<any> {
    const getUrl = this.baseUrlBase + `payments/get-transactions`;
    return this.http.get(getUrl);
  }

  public myTransactions(query: FeesQuery): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        session: query.session,
        term: query.term,
        type: query.type,
        payment_gateway: '',
        status: query.status,
        skip: query.skip,
        limit: query.limit,
      },
    });
    return this.reqS.get(this.baseUrl + 'get-transactions', params);
  }

  registerSubjects(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'register_subject';
    return this.http.post(getUrl, data).pipe(retry(2), tap());
  }

  exemptStudent(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'add_exception_subject';
    return this.http.post(getUrl, data).pipe(tap());
  }
  revoveException(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'remove_exception_subject';
    return this.http.post(getUrl, data).pipe(tap());
  }

  getMinistrySubjects(): Observable<any> {
    const getUrl = this.baseUrl + 'all_subjects?skip=0&limit=100000';
    return this.http.get(getUrl).pipe(retry(2), tap());
  }

  getSchoolSubjects(): Observable<any> {
    const getUrl = this.baseUrl + 'registered_subjects';
    return this.http.get(getUrl).pipe(retry(2), tap());
  }

  removeSubject(id): Observable<any> {
    const getUrl = this.baseUrl + `unregister_subject/${id}`;
    return this.http.get(getUrl).pipe(retry(2), tap());
  }

  getSubjectById(id): Observable<any> {
    const getUrl = this.baseUrl + `registered_subjects/${id}`;
    return this.http.get(getUrl).pipe(retry(2), tap());
  }

  // Results APIs
  allResult(query): Observable<any> {
    const getUrl =
      this.baseUrl +
      `results?skip=${query.skip}&limit=${query.limit}&createdAt:desc&grade=${query.grade}&class=${query.klas}&session=${query.session}`;
    return this.http.get(getUrl).pipe(retry(2), tap());
  }

  studentResult(query): Observable<any> {
    const getUrl =
      this.baseUrl + `result/${query.reg}?session=${query.session}`;
    return this.http.get(getUrl).pipe(retry(2), tap());
  }

  create(data): Observable<any> {
    const getUrl =
      'https://my-school-portal1.herokuapp.com/api/admins/create_subject';
    // let getUrl = 'https://school-portal-staging-v1.herokuapp.com/api/admins/create_subject'
    return this.http.post(getUrl, data).pipe(tap());
  }

  // Exams and Invoice
  getExams(): Observable<any> {
    const getUrl = this.baseUrl + 'exams';
    return this.http.get(getUrl).pipe(retry(1), tap());
  }
  myInvoices(): Observable<any> {
    const getUrl = this.baseUrl + 'invoices';
    return this.http.get(getUrl).pipe(retry(1), tap());
  }
  myInvoiceDetailIDL(ref: string): Observable<any> {
    const getUrl = this.idlUrl + `transaction/fidelity/unit/${ref}`;
    return this.http.get(getUrl).pipe(tap());
  }
  myInvoiceFromIDL(id): Observable<any> {
    const getUrl = this.idlUrl + `invoicepayment/fidelity/${id}/20202021`;
    return this.http.get(getUrl).pipe(retry(1), tap());
  }
  invoiceStudents(ref: string): Observable<any> {
    const getUrl = this.baseUrl + `invoice_students/${ref}`;
    return this.http.get(getUrl).pipe(retry(1), tap());
  }
  generateInvoice(data: object): Observable<any> {
    const getUrl = this.baseUrl + 'generate-invoice';
    return this.http.post(getUrl, data).pipe(tap());
  }
  invoiceDetails(id: string): Observable<any> {
    const getUrl = this.baseUrl + `invoice/${id}`;
    return this.http.get(getUrl).pipe(retry(1), tap());
  }
}
