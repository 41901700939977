import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import { CheckResultComponent } from './Core/check-result/check-result.component';
import { ForgotPasswordComponent } from './Core/forgot-password/forgot-password.component';
import { LoginComponent } from './Core/login/login.component';
import { RegisterComponent } from './Core/register/register.component';
import { ResetPasswordComponent } from './Core/reset-password/reset-password.component';
import { SidePanelComponent } from './Core/side-panel/side-panel.component';
import { HttpinterceptorService } from './interceptor/httpinterceptor.service';
import { MaterialModule } from './material/material.module';
import { UtilService } from './Services/util.service';
import { GenerateregpinComponent } from './Core/generateregpin/generateregpin.component';

export function tokenGetter(): any {
  return localStorage.getItem('/key');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    CheckResultComponent,
    ConfirmPaymentComponent,
    SidePanelComponent,
    GenerateregpinComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxCsvParserModule,
    NgxSpinnerModule,
    JwtModule.forRoot({
      config: {},
    }),
  ],
  providers: [
    CookieService,
    UtilService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpinterceptorService,
      multi: true,
    },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
