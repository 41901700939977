export const primary = [
    {
        code: 100,
        title: 'Day Care',
    },
    {
        code: 200,
        title: 'Creche',
    },
    {
        code: 300,
        title: 'Pre Nursery',
    },
    {
        code: 400,
        title: 'Nursery 1',
    },
    {
        code: 500,
        title: 'Nursery 2',
    },
    {
        code: 600,
        title: 'Nursery 3',
    },
    {
        code: 1,
        title: 'Primary 1',
    },
    {
        code: 2,
        title: 'Primary 2',
        fee: 18000
    },
    {
        code: 3,
        title: 'Primary 3',
    },
    {
        code: 4,
        title: 'Primary 4',
    },
    {
        code: 5,
        title: 'Primary 5',
    },
    {
        code: 6,
        title: 'Primary 6',
    },
];
export const adult = [
    {
        code: 1000,
        title: 'Adult',
    }
]
export const secondary = [
    {
        code: 7,
        title: 'JS 1',
    },
    {
        code: 8,
        title: 'JS 2',
    },
    {
        code: 9,
        title: 'JS 3',
    },
    {
        code: 10,
        title: 'SS 1',
    },
    {
        code: 11,
        title: 'SS2 ',
    },
    {
        code: 12,
        title: 'SS 3',
    },
];

export const optional_class = [
    {
        code: 11,
        title: 'SS 2 '
    },
    {
        code: 12,
        title: 'SS 3'
    },
];

export const primary_array = [
    100, 200, 300, 400, 500, 600, 1, 2, 3, 4, 5, 6
];

export const adult_array = [
    1000
];

export const secondary_array = [
    7, 8, 9, 10, 11, 12
];
