export const zones = [
  {
    zone: 'ENUGU',
    lgas: [
      {
        lga: 'Enugu East',
        areas: ['Enugu East', 'Enugu East Central', 'Ujodo'],
      },
      {
        lga: 'Enugu North',
        areas: ['Enugu North', 'Enugu Central'],
      },
      {
        lga: 'Isi-Uzo',
        areas: ['Isi Uzo Main', 'Eha-Amufu'],
      },
    ],
  },
  {
    zone: 'AGBANI',
    lgas: [
      {
        lga: 'Enugu South',
        areas: ['Enugu South', 'Enugu west'],
      },
      {
        lga: 'Nkanu-West',
        areas: [
          'NKanu West',
          'Nkanu Central',
          'Awkunanaw',
          'Akpugo',
          'Amankanu/ Asunkanu',
        ],
      },
      {
        lga: 'Nkanu-East',
        areas: [' Nkanu East'],
      },
    ],
  },
  {
    zone: 'UDI',
    lgas: [
      {
        lga: 'Udi',
        areas: ['Udi Main', 'Udi central', 'Ojebe-Ogene/Ezedike'],
      },
      {
        lga: 'Ezeagu',
        areas: ['Ezeagu Main', 'Ezeagu North Central', 'Ezeagu South West'],
      },
    ],
  },
  {
    zone: 'AWGU',
    lgas: [
      {
        lga: 'Awgu',
        areas: ['Awgu', 'Anike', 'Mbanabo'],
      },
      {
        lga: 'Aninri',
        areas: ['Aninri West', 'Aninri East', 'Aninri North'],
      },
      {
        lga: 'Orji River',
        areas: ['Oji-River', 'Mmam River'],
      },
    ],
  },
  {
    zone: 'NSUKKA',
    lgas: [
      {
        lga: 'Nsukka',
        areas: ['Nsukka Central', 'Nsukka East', 'Nsukka West'],
      },
      {
        lga: 'Uzo Uwani',
        areas: ['Uzo Uwani', 'Igbo Ano /Adada', 'Ogboli'],
      },
      {
        lga: 'Igbo Etiti',
        areas: ['Igbo Etiti Central', 'Igbo Etiti East', 'Igbo Etiti west'],
      },
    ],
  },
  {
    zone: 'OBOLLO',
    lgas: [
      {
        lga: 'IGbo-Eze North',
        areas: [
          'Igbo-Eze North Central',
          'Igbo-Eze North East',
          'Igbo-Eze North West',
        ],
      },
      {
        lga: 'Igbo-Eze South',
        areas: ['Igbo-Eze south'],
      },
      {
        lga: 'Udenu',
        areas: ['Udenu', 'Udunedem', 'Orba'],
      },
    ],
  },
];

export const lgas = [
  'Aninri',
  'Awgu',
  'Enugu East',
  'Enugu North',
  'Enugu South',
  'Ezeagu',
  'Igbo Etiti',
  'IGbo-Eze North',
  'Igbo-Eze South',
  'Isi-Uzo',
  'Nkanu-East',
  'Nkanu-West',
  'Nsukka',
  'Orji River',
  'Udenu',
  'Udi',
  'Uzo Uwani',
];
