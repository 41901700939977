import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { UtilService } from 'src/app/Services/util.service';
import { lgas, zones } from '../lgas';
import { GenerateregpinComponent } from '../generateregpin/generateregpin.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  signupForm: FormGroup;
  types = [];
  lgas = [];
  areas = [];
  zones = zones;
  confirmed = false;
  phone_valid = 0;
  loader = false;
  categories = [];
  catLoader = false;
  typeLoader = false;
  constructor(
    private formbuilder: FormBuilder,
    private api: ApiService,
    private util: UtilService,
    private matDialog: MatDialog,
    private router: Router
  ) {
    this.signupForm = this.formbuilder.group(
      {
        password: ['', Validators.required],
        pin: [
          '',
          [
            Validators.required,
            Validators.minLength(12),
            Validators.maxLength(12),
          ],
        ],
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        vpwd: ['', Validators.required],
        type: ['', Validators.required],
        status: ['approved', Validators.required],
        lga: ['', Validators.required],
        zone: ['', Validators.required],
        area: ['', Validators.required],
        category: ['', Validators.required],
        phone: ['', Validators.required],
      },
      { validator: this.matchingfield('password', 'vpwd') }
    );
  }

  ngOnInit(): void {
    this.getCatergories();
    this.getTypes();
  }

  // making sure phone number field contains only numbers
  validatePhone(num): void {
    const regex = /^\d+$/;
    const val = this.signupForm.value.phone.trim();
    if (regex.test(val)) {
      this.phone_valid = 1;
    }
    if (!regex.test(val)) {
      this.phone_valid = 2;
    }
  }
  // matching field verification for password
  matchingfield(field1, field2): any {
    return (signupform) => {
      if (
        signupform.controls[field1].value !== signupform.controls[field2].value
      ) {
        return { mismatchedFields: true };
      }
    };
  }

  regValid(control): boolean {
    return (
      this.signupForm.controls[control].touched &&
      this.signupForm.controls[control].invalid
    );
  }

  // populate lgas by selected zone
  selectZone(val: string): void {
    const zone = this.zones.find((item) => {
      return item.zone === val;
    });
    this.lgas = zone.lgas;
    this.signupForm.get('lga').setValue(this.lgas[0].lga); // prefill lga;
    this.signupForm.updateValueAndValidity();
    this.selectLGA(this.lgas[0].lga);
  }

  // populate areas by selected lga
  selectLGA(val: string): void {
    const lga = this.lgas.find((item) => {
      return item.lga === val;
    });
    this.areas = lga.areas;
    this.signupForm.get('area').setValue(this.areas[0]); // prefill area
    this.signupForm.updateValueAndValidity();
  }

  async register(): Promise<void> {
    this.loader = true;
    const form = this.signupForm.value;
    form.pin = form.pin.trim();
    this.api.signup(form).subscribe(
      (res: any) => {
        this.util.succesSnackbar(res.msg);
        this.util.setUserObject(res.school);
        this.util.setToken(res.token);
        this.router.navigate(['/dashboard']);
      },
      (err) => ((this.confirmed = false), (this.loader = false))
    );
  }

  async getCatergories(): Promise<void> {
    this.catLoader = true;
    this.api.getCategroy().subscribe((res: any) => {
      this.categories = res.category.filter((el) => {
        return (
          el.code === '22' ||
          el.code === '24' ||
          el.code === '25' ||
          el.code === '26' ||
          el.code === '32'
        );
      });
      this.catLoader = false;
    });
  }

  async getTypes(): Promise<void> {
    this.typeLoader = true;
    this.api.getTypes().subscribe((res: any) => {
      this.types = res;
    });
    this.typeLoader = false;
  }

  confirm(): void {
    this.confirmed = true;
  }

  buyPin() {
    const dialogConfig: any = this.util.dialogConfig();
    dialogConfig.width = '576px';
    const dialogRef = this.matDialog.open(
      GenerateregpinComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //
      }
    });
  }
}
