export const exams = [
    {
        title: 'Transition Examination into Junior Secondary School (TEJS)',
        classes: [
            6
        ],
        fees: [
            {
                category_id: '22',
                amount: '850'
            },
            {
                category_id: '24',
                amount: '850'
            },
            {
                category_id: '25',
                amount: '850'
            },
            {
                category_id: '26',
                amount: '100'
            },
            {
                category_id: '27',
                amount: '850'
            },
            {
                category_id: '28',
                amount: '100'
            },
            {
                category_id: '29',
                amount: '850'
            }
        ]
    },
    {
        title: 'Primary School Completion Certificate Examination (PSCCE)',
        classes: [
            6
        ],
        fees: [
            {
                category_id: '22',
                amount: '850'
            },
            {
                category_id: '24',
                amount: '850'
            },
            {
                category_id: '25',
                amount: '850'
            },
            {
                category_id: '26',
                amount: '850'
            },
            {
                category_id: '27',
                amount: '850'
            },
            {
                category_id: '28',
                amount: '100'
            },
            {
                category_id: '29',
                amount: '850'
            }
        ]
    },
    {
        title: 'Basic Education Certificate Examination (BECE)',
        classes: [
            6
        ],
        fees: [
            {
                category_id: '22',
                amount: '100'
            },
            {
                category_id: '24',
                amount: '100'
            },
            {
                category_id: '25',
                amount: '100'
            },
            {
                category_id: '26',
                amount: '100'
            },
            {
                category_id: '27',
                amount: '100'
            },
            {
                category_id: '28',
                amount: '100'
            },
            {
                category_id: '29',
                amount: '100'
            }
        ]
    },
    {
        title: 'Uniform promotion exam',
        classes: [
            4,
            5,
            7,
            8,
            10,
            11
        ],
        fees: [
            {
                category_id: '22',
                amount: '100'
            },
            {
                category_id: '24',
                amount: '100'
            },
            {
                category_id: '25',
                amount: '100'
            },
            {
                category_id: '26',
                amount: '100'
            },
            {
                category_id: '27',
                amount: '100'
            },
            {
                category_id: '28',
                amount: '100'
            },
            {
                category_id: '29',
                amount: '100'
            }
        ]
    },
    {
        title: 'Entrance into Science Schools (JS3)',
        classes: [
            9
        ],
        fees: [
            {
                category_id: '22',
                amount: '100'
            },
            {
                category_id: '24',
                amount: '100'
            },
            {
                category_id: '25',
                amount: '100'
            },
            {
                category_id: '26',
                amount: '100'
            },
            {
                category_id: '27',
                amount: '100'
            },
            {
                category_id: '28',
                amount: '100'
            },
            {
                category_id: '29',
                amount: '100'
            }
        ]
    }
];
