import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { UtilService } from 'src/app/Services/util.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  pwdForm: FormGroup;
  types = [];
  loader = false;
  constructor(
    private formbuilder: FormBuilder,
    private api: ApiService,
    private util: UtilService,
    private router: Router
  ) { 
    this.pwdForm = this.formbuilder.group({
      token : ['', Validators.required],
      newPassword : ['', Validators.required],
      vpwd : ['', Validators.required],
    },{validator: this.matchingfield('newPassword','vpwd')});
  }

  ngOnInit(): void {
  }

  // matching field verification for password
  matchingfield(field1,field2){
    return signupform =>{
      if(signupform.controls[field1].value !==
        signupform.controls[field2].value){
        return {mismatchedFields : true}
      }
    }
  }
  regValid(control){
    return this.pwdForm.controls[control].touched &&
    this.pwdForm.controls[control].invalid;
  }
  async resetPwd(){
    this.loader = true;
    this.api.resetPassword(this.pwdForm.value).subscribe((res:any)=>{
      this.util.succesSnackbar(res.msg);
      this.router.navigate(['/'])
    },err=>(
      this.util.errorSnackbar(err.error.text?err.error.text:'Network failed, try again'),
      this.loader = false
    ))
  }

}

