<div class="wrapper">
  <img src="../../../assets/img/top_Vector.svg" alt="" class="top_vector" />
  <img
    src="../../../assets/img/bottom_vector.svg"
    alt=""
    class="bottom_vector"
  />
  <div class="side_bg">
    <div class="text">
      <h6 class="text-white fw-800 f-40">Examportal</h6>
      <p class="text-white">School management made easy</p>
      <p class="text-white">In Partnership with Exam Development Center</p>
    </div>
  </div>
  <div class="content">
    <div class="form_area">
      <div class="top text-center">
        <img src="../../../assets/img/logo.png" alt="" /> <br />
        <span class="font-weight-bold f-16 text-app-secondary"
          >Examportal Nigeria</span
        >
        <h6 class="f-16 mt-3">Create Portal Account</h6>
      </div>

      <form [formGroup]="signupForm">
        <div class="row">
          <div class="group col-12 position-relative">
            <input
              type="text"
              id="email"
              formControlName="pin"
              class="pin-input"
              placeholder="PIN"
            />
            <span class="buy-pin bg-app-primary" (click)="buyPin()"
              >Buy PIN</span
            >
            <small class="error" *ngIf="regValid('pin')">
              <small
                class="error"
                *ngIf="signupForm.get('pin').errors.required"
              ></small>
              <small
                class="error"
                *ngIf="signupForm.get('pin').errors.minlength"
                >Incomplete PIN digits</small
              >
              <small
                class="error"
                *ngIf="signupForm.get('pin').errors.maxlength"
                >PIN must be 12 digits</small
              >
            </small>
            <!-- <div class="d-flex justify-content-between px-2 f-12 mt-2">
              <span class="text-app-secondary">Do not have PIN?</span>
              <span
                class="text-underline text-app-primary ml-3 cursor"
                (click)="buyPin()"
                >Buy PIN</span
              >
            </div> -->
          </div>
          <div class="group col-12">
            <input
              type="text"
              class=""
              formControlName="name"
              id="pwd"
              placeholder="School Name"
            />
          </div>
          <div class="group select col-md-6 position-relative">
            <select
              name="cat"
              id=""
              class="custom-select"
              formControlName="category"
            >
              <option value="" disabled selected>Select school category</option>
              <option *ngFor="let item of categories" [value]="item?.code">
                {{ item?.name }}
              </option>
            </select>
            <img
              src="../../../assets/img/select arrow.svg"
              alt=""
              *ngIf="!catLoader"
            />
            <i
              class="fas fa-circle-notch fa-spin text-app-primary"
              *ngIf="catLoader"
            ></i>
          </div>
          <div class="group select col-md-6 position-relative">
            <select
              name="type"
              id=""
              class="custom-select"
              formControlName="type"
            >
              <option value="" disabled selected>Select school type</option>
              <option *ngFor="let item of types" [value]="item?.code">
                {{ item?.name }}
              </option>
            </select>
            <img
              src="../../../assets/img/select arrow.svg"
              alt=""
              *ngIf="!typeLoader"
            />
            <i
              class="fas fa-circle-notch fa-spin text-app-primary"
              *ngIf="typeLoader"
            ></i>
          </div>
          <div class="group select col-md-6">
            <select
              name="zone"
              id=""
              class="custom-select"
              formControlName="zone"
              #zone
              (change)="selectZone(zone.value)"
            >
              <option value="" disabled selected>Select Zone</option>
              <option *ngFor="let item of zones" [value]="item.zone">
                {{ item.zone }}
              </option>
            </select>
            <img src="../../../assets/img/select arrow.svg" alt="" />
          </div>
          <div class="group select col-md-6">
            <select
              name="lga"
              id=""
              class="custom-select text-capitalize"
              formControlName="lga"
              #lga
              (change)="selectLGA(lga.value)"
            >
              <option value="" disabled selected>Select LGA</option>
              <option *ngFor="let item of lgas" [value]="item.lga">
                {{ item.lga }}
              </option>
            </select>
            <img src="../../../assets/img/select arrow.svg" alt="" />
          </div>
          <div class="group select col-md-6">
            <select
              name="area"
              id=""
              class="custom-select text-capitalize"
              formControlName="area"
            >
              <option value="" disabled selected>Select Area</option>
              <option *ngFor="let item of areas" [value]="item">
                {{ item }}
              </option>
            </select>
            <img src="../../../assets/img/select arrow.svg" alt="" />
          </div>
          <div class="group col-md-6">
            <input
              type="text"
              class=""
              (keyup)="validatePhone($event.target.value)"
              formControlName="phone"
              placeholder="Phone Number"
              id="phone"
            />
            <small class="error f-12" *ngIf="phone_valid === 2"
              >Incorrect phone number format</small
            >
          </div>
          <div class="group col-12">
            <input
              type="text"
              class=""
              formControlName="email"
              id="pwd"
              placeholder="School Email"
            />
            <small class="error" *ngIf="regValid('email')">
              <small
                class="error"
                *ngIf="signupForm.get('email').errors.required"
              ></small>
              <small class="error" *ngIf="signupForm.get('email').errors.email"
                >Email is not valid</small
              >
            </small>
          </div>
          <div class="group col-md-6">
            <input
              type="password"
              formControlName="password"
              class=""
              placeholder="Password"
            />
          </div>
          <div class="group col-md-6">
            <input
              type="password"
              formControlName="vpwd"
              class=""
              placeholder="Verify Password"
            />
            <small
              class="error"
              *ngIf="
                signupForm.errors?.mismatchedFields &&
                signupForm.get('vpwd').touched
              "
              >Passwords do not match!</small
            >
          </div>
        </div>
        <button
          class="bg-app-secondary text-white btn w-100"
          *ngIf="confirmed"
          (click)="register()"
          [disabled]="!signupForm.valid || phone_valid === 2 || loader"
        >
          Confirm and Register
          <i class="fas fa-circle-notch fa-spin ml-4" *ngIf="loader"></i>
        </button>
        <button
          class="bg-app-secondary text-white btn w-100"
          *ngIf="!confirmed"
          (click)="confirm()"
        >
          Register
        </button>
      </form>
      <footer class="text-center f-14">
        <div class="mb-3">
          <span class="text-app-secondary">Already have a portal Account?</span>
          <span class="cursor ml-2 text-app-primary" [routerLink]="['/']"
            >Login</span
          >
        </div>
        <span>
          Support:
          <a href="tel:+" class="text-app-primary ml-1">07033902120</a> or
          <a href="tel:+ 07066507116" class="ml-1 text-app-primary"
            >07066507116</a
          >
        </span>
        <div class="text-center my-4">
          <span
            class="btn bg-app-primary text-white text-decoration-none"
            [routerLink]="['/check-result']"
          >
            Check Result
          </span>
        </div>
      </footer>
    </div>
  </div>
</div>
