import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { UtilService } from 'src/app/Services/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loader = false;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private util: UtilService
  ) {
    this.loginForm = this.formbuilder.group({
      password: ['', Validators.required],
      email: ['', Validators.required],
    });
  }

  ngOnInit(): void {

    // if(this.util.isLoggedIn()){
    //   this.util.succesSnackbar('You are already Signed In')
    //   this.router.navigate(['/dashboard'])
    // }
    this.util.removeAllData();
  }
  async login(): Promise<void> {
    this.loader = true;
    // this.loginForm.value.password = this.loginForm.value.password.toLowerCase();
    // this.loginForm.value.email = this.loginForm.value.email.toLowerCase();
    this.api.login(this.loginForm.value).subscribe((res: any) => {
      this.util.succesSnackbar('Login successful');
      this.util.setUserObject(res.school);
      this.util.setToken(res.token);
      this.router.navigate(['/dashboard']);
    }, err => (
      err.error.msg ? this.util.errorSnackbar(err.error.msg) : '',
      this.loader = false
    ));
  }

  // async create(){
  //   subject.forEach(item=>{
  //     item.isOptional =  JSON.parse(item.isOptional.toLowerCase());
  //     item.department = item.department.toLowerCase();
  //     this.api.create(item).subscribe((res:any)=>{

  //     },err=>(
  //       //this.data_loader = false
  //       console.log(err)

  //     ))
  //   })

  // }

}
