<div class="wrapper">
  <img src="../../../assets/img/top_Vector.svg" alt="" class="top_vector" />
  <img
    src="../../../assets/img/bottom_vector.svg"
    alt=""
    class="bottom_vector"
  />
  <div class="side_bg">
    <div class="text">
      <h6 class="text-white fw-800 f-40">Examportal</h6>
      <p class="text-white">School management made easy</p>
    </div>
  </div>
  <div class="content">
    <div class="form_area">
      <div class="top text-center">
        <img src="../../../assets/img/logo.png" alt="" /> <br />
        <span class="font-weight-bold f-16 text-app-secondary"
          >Examportal Nigeria</span
        >
      </div>
      <div>
        <div
          class="confirm-box border-app-primary text-app-primary"
          *ngIf="loading"
        >
          <p>Please hold on, we are getting confirming your payment</p>
          <i class="fas fa-circle-notch fa-spin fa-4x"></i>
        </div>
        <div
          class="success-container"
          *ngIf="!loading && transaction && transaction"
        >
          <div
            class="circle-border"
            [ngClass]="{ error: !transaction.status }"
          ></div>
          <div class="circle">
            <div
              class="success"
              [ngClass]="{ error: !transaction.status }"
            ></div>
          </div>
          <div *ngIf="transaction.status" class="status-actions">
            <p class="status-text">
              Your transaction is successfully completed
            </p>
            <button
              class="bg-app-primary text-white btn w-100"
              *ngIf="transaction.pin && transaction.pin.type !== 'registration'"
              (click)="myPins()"
            >
              Go to PINs
            </button>
            <button
              class="bg-app-primary text-white btn w-100"
              [routerLink]="['/register']"
              [queryParams]="{ token: transaction?._id }"
              *ngIf="transaction.pin && transaction.pin.type === 'registration'"
            >
              Continue Registration
            </button>
          </div>
          <div *ngIf="!transaction.status" class="status-actions">
            <p class="status-text">
              Your transaction is not completed at the moment
            </p>
            <button
              class="bg-app-secondary text-white btn w-100"
              (click)="retry()"
            >
              Retry payment
            </button>
          </div>
        </div>
        <footer class="text-center f-14 mt-5">
          <span class="text-app-secondary">Already have a portal Account?</span>
          <span class="cursor ml-1 text-app-primary" [routerLink]="['/']"
            >Login</span
          >
        </footer>
      </div>
    </div>
  </div>
</div>
