import { Component, OnInit } from '@angular/core';
import { ApiService } from '../Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { PinTransaction } from '../Core/models';

@Component({
  selector: 'app-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.scss'],
})
export class ConfirmPaymentComponent implements OnInit {
  loading = false;
  ref: string;
  transaction: PinTransaction;
  constructor(
    private apiS: ApiService,
    private activateDroute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const ref =
      this.activateDroute.snapshot.queryParamMap.get('paymentReference');
    if (ref) {
      this.ref = ref;
      this.getTransaction();
    } else {
      this.router.navigate(['/']);
    }
  }

  getTransaction() {
    this.loading = true;
    this.apiS.transactionByRef(this.ref).subscribe({
      next: (res) => {
        this.transaction = res.data;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }
  myPins() {
    this.router.navigate(['/dashboard/admission-center'], {
      queryParams: { tab: 'pin' },
      queryParamsHandling: 'merge',
    });
  }

  retry() {
    if (this.transaction.payment_gateway === 'monnify') {
      window.open(this.transaction.other_details[0].checkoutUrl, '_blank');
    } else {
      window.open(
        environment.unifiedUrl + this.transaction.transaction_ref,
        '_blank'
      );
    }
  }
}
