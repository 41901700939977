import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilService } from 'src/app/Services/util.service';

@Injectable({
  providedIn: 'root'
})
export class HttpinterceptorService {
  constructor(
    private util: UtilService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.util.getToken();
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          // "Content-Type": "application/json",
          Accept: 'application/json',
          Authorization: `Bearer ${currentUser}`
        }
      });
    }
    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 503 || (typeof (err.error) === 'string' && err.error.includes('space quota'))) {
            this.util.errorSnackbar('Service unavailable, pls contact admin');
            return;
          }
          if (err.status === 404) {
            this.util.errorSnackbar(err.statusText);
          }
          if (err.status === 403) {
            this.router.navigate(['/']);
          }
          if (err.status === 401) {
            this.util.errorSnackbar('session expired, please login again');
            this.util.logout();
          }
          if (err.status === 0) {
            this.util.errorSnackbar('Please make sure you are connected to the internet');
            return;
          }
          else {
            this.util.errorSnackbar(this.util.parseError(err, err.error));
            return;
          }

        }
      }));
  }
}
